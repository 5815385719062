import React from 'react';

export const privacyPolicy = '<html>\n' +
    '<head>\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
    '    <title>Privacy Policy</title>\n' +
    '    <style>\n' +
    '        body {\n' +
    '            font-family: Arial, sans-serif;\n' +
    '            line-height: 1.6;\n' +
    '            margin: 0;\n' +
    '            padding: 0;\n' +
    '        }\n' +
    '\n' +
    '        .container {\n' +
    '            max-width: 960px;\n' +
    '            margin: 0 auto;\n' +
    '            padding: 20px;\n' +
    '        }\n' +
    '\n' +
    '        h1, h4, h5, h6 {\n' +
    '            text-align: left;\n' +
    '        }\n' +
    '\n' +
    '        p {\n' +
    '            margin-bottom: 20px;\n' +
    '        }\n' +
    '\n' +
    '        ul {\n' +
    '            padding-left: 40px;\n' +
    '        }\n' +
    '\n' +
    '        /* Media Query for smaller screens */\n' +
    '        @media screen and (max-width: 768px) {\n' +
    '            h1 {\n' +
    '                font-size: 28px;\n' +
    '            }\n' +
    '\n' +
    '            h4 {\n' +
    '                font-size: 18px;\n' +
    '            }\n' +
    '        }\n' +
    '    </style>\n' +
    '</head>\n' +
    '<body>\n' +
    '<div class="container">\n' +
    '    <div className="p-5">\n' +
    '        <h1 align="center">\n' +
    '            <strong>Privacy Policy</strong>\n' +
    '        </h1>\n' +
    '        <h4>\n' +
    '            <strong>Thank you for using MuseumsAround!</strong>\n' +
    '        </h4>\n' +
    '        <p>\n' +
    '            Ihsan Katmer built the MuseumsAround app as a Freemium app. This\n' +
    '            SERVICE is provided by Ihsan Katmer at no cost and is intended for use\n' +
    '            as is.\n' +
    '\n' +
    '            This page is used to inform visitors regarding our policies regarding\n' +
    '            the collection, use, and disclosure of Personal Information if anyone\n' +
    '            decided to use our Service.\n' +
    '\n' +
    '            If you choose to use our Service, then you agree to the collection and\n' +
    '            use of information in relation to this policy. The Personal\n' +
    '            Information that we collect is used for providing and improving the\n' +
    '            Service. We will not use or share your information with anyone except\n' +
    '            as described in this Privacy Policy.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Information Collection and Use</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            For a better experience, while using our Service, we may require you\n' +
    '            to provide us with certain personally identifiable information,\n' +
    '            including but not limited to user analytics and ad data. The\n' +
    '            information that we request will be retained on your device and is not\n' +
    '            collected by us in any way.\n' +
    '\n' +
    '            The app does use third-party services that may collect information\n' +
    '            used to identify you.\n' +
    '\n' +
    '            Link to Privacy Policy of third-party service providers used by the\n' +
    '            app.\n' +
    '        </p>\n' +
    '        <a\n' +
    '                href="https://firebase.google.com/policies/analytics"\n' +
    '                shape="rect"\n' +
    '        >\n' +
    '            Google Analytics for Firebase\n' +
    '        </a>\n' +
    '        <p>\n' +
    '\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            It will be clear what personal information we are asking from you as\n' +
    '            well as the reasons for doing so.\n' +
    '        </p>\n' +
    '        <h6>\n' +
    '            <strong> A- Information We Get</strong>\n' +
    '        </h6>\n' +
    '        <p>\n' +
    '            We may receive additional information about you if you contact us\n' +
    '            directly, such as your name, phone number, email address, attachments\n' +
    '            you send, or any other information you may choose to provide.\n' +
    '\n' +
    '            We may also receive information about you from our service providers\n' +
    '            such as log files as it is a standard procedure for hosting providers.\n' +
    '            The information collected by log file includes internet protocol (IP)\n' +
    '            addresses, browser type, Internet Service Provider (ISP), date and\n' +
    '            time stamp, and possibly the number of clicks. This information is not\n' +
    '            linked to any personally identifiable information. The purpose of the\n' +
    '            information is for analyzing trends, administering the site, tracking\n' +
    '            users\' movement on the website, and gathering demographic information.\n' +
    '        </p>\n' +
    '        <h6>\n' +
    '            <strong> A- Information We Get</strong>\n' +
    '        </h6>\n' +
    '        <p>\n' +
    '            When you start using the app, we may ask for your location\n' +
    '            information, and we get the precise location information and also if\n' +
    '            you place an order, we may receive your billing and payment\n' +
    '            information.\n' +
    '        </p>\n' +
    '        <h6>\n' +
    '            <strong>How we use the information you provide</strong>\n' +
    '        </h6>\n' +
    '        <p>\n' +
    '            We use your location information to show you the distance information\n' +
    '            between you and other museums and the nearest museum to your current\n' +
    '            location.\n' +
    '\n' +
    '            We do not retain any location, billing, or payment information and I\n' +
    '            do not process that information. We do not store any of them on the\n' +
    '            server.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Service Providers</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            We may employ third-party companies and individuals due to the\n' +
    '            following reasons:\n' +
    '        </p>\n' +
    '        <ul type="disc">\n' +
    '            <li>\n' +
    '                o facilitate our Service;\n' +
    '            </li>\n' +
    '            <li>\n' +
    '                To provide the Service on our behalf;\n' +
    '            </li>\n' +
    '            <li>\n' +
    '                To perform Service-related services; or\n' +
    '            </li>\n' +
    '            <li>To assist us in analyzing how our Service is used.</li>\n' +
    '        </ul>\n' +
    '        <p>\n' +
    '            We want to inform users of this Service that these third parties have\n' +
    '            access to your Personal Information. The reason is to perform the\n' +
    '            tasks assigned to them on our behalf. However, they are obligated not\n' +
    '            to disclose or use the information for any other purpose.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Security</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            We value your trust in providing us your Personal Information, thus we\n' +
    '            are striving to use commercially acceptable means of protecting it.\n' +
    '            But remember that no method of transmission over the internet, or\n' +
    '            method of electronic storage is 100% secure and reliable, and I cannot\n' +
    '            guarantee its absolute security.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Links to Other Sites</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            This Service may contain links to other sites. If you click on a\n' +
    '            third-party link, you will be directed to that site. Note that these\n' +
    '            external sites are not operated by us. Therefore, we strongly advise\n' +
    '            you to review the Privacy Policy of these websites. we have no control\n' +
    '            over and assume no responsibility for the content, privacy policies,\n' +
    '            or practices of any third-party sites or services.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Children\'s Privacy</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            These Services do not address anyone under the age of 13. I do not\n' +
    '            knowingly collect personally identifiable information from children\n' +
    '            under 13 years of age. In the case we discover that a child under 13\n' +
    '            has provided us with personal information, We immediately delete this\n' +
    '            from our servers. If you are a parent or guardian and you are aware\n' +
    '            that your child has provided us with personal information, please\n' +
    '            contact us so that we will be able to do the necessary actions.\n' +
    '        </p>\n' +
    '        <h5>\n' +
    '            <strong>Changes to This Privacy Policy</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            This Privacy Policy may be changed periodically and without prior\n' +
    '            notice. You are advised to regularly review the Privacy Policy for\n' +
    '            possible changes, although where we make substantial changes, we will\n' +
    '            try and give you notice of this.\n' +
    '        </p>\n' +
    '        <span>This policy is effective as of July 14, 2023</span>\n' +
    '        <h5>\n' +
    '            <strong>Contact</strong>\n' +
    '        </h5>\n' +
    '        <p>\n' +
    '            If you have any questions or suggestions about the Privacy Policy, do\n' +
    '            not hesitate to contact us at <span><strong>museumsaround@gmail.com</strong></span>\n' +
    '        </p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '</body>\n' +
    '</html>'


export const termsAndConditions = "<html>\n" +
    "<head>\n" +
    "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
    "    <title>Terms and Conditions</title>\n" +
    "    <style>\n" +
    "        body {\n" +
    "            font-family: Arial, sans-serif;\n" +
    "            line-height: 1.6;\n" +
    "            margin: 0;\n" +
    "            padding: 0;\n" +
    "        }\n" +
    "\n" +
    "        .container {\n" +
    "            max-width: 960px;\n" +
    "            margin: 0 auto;\n" +
    "            padding: 20px;\n" +
    "        }\n" +
    "\n" +
    "        h1, h4, h5, h6 {\n" +
    "            text-align: left;\n" +
    "        }\n" +
    "\n" +
    "        p {\n" +
    "            margin-bottom: 20px;\n" +
    "            text-align: left; /* Left align text */\n" +
    "        }\n" +
    "\n" +
    "        /* Media Query for smaller screens */\n" +
    "        @media screen and (max-width: 768px) {\n" +
    "            h1 {\n" +
    "                font-size: 28px;\n" +
    "            }\n" +
    "\n" +
    "            h4 {\n" +
    "                font-size: 18px;\n" +
    "            }\n" +
    "        }\n" +
    "    </style>\n" +
    "</head>\n" +
    "<body>\n" +
    "<div class=\"container\">\n" +
    "    <div className=\"p-5\">\n" +
    "        <h1 align=\"center\">\n" +
    "            <strong>Terms and Conditions</strong>\n" +
    "        </h1>\n" +
    "        <h4>\n" +
    "            <strong>Thank you for using MuseumsAround!</strong>\n" +
    "        </h4>\n" +
    "        <p>\n" +
    "            Please read these terms and conditions of use carefully before using\n" +
    "            our mobile application. By accessing or using the Services or any\n" +
    "            functionality thereof on any computer, mobile phone, tablet, console,\n" +
    "            or other device, you confirm that you have read, understood, and\n" +
    "            agreed to be bound by these Terms and Conditions of Use. If you do not\n" +
    "            agree to these Terms and Conditions of Use, please do not access the\n" +
    "            Services or any functionality thereof and please refrain from using\n" +
    "            the app.\n" +
    "        <p/>\n" +
    "        All content on the app is owned by Ihsan Katmer. You’re not allowed to\n" +
    "        copy or modify the app, any part of the app, or the trademarks in any\n" +
    "        way. you’re not allowed to attempt to extract the source code of the\n" +
    "        app, and you also shouldn’t try to translate the app into other\n" +
    "        languages or make derivative versions. The app itself, and all the\n" +
    "        trademarks, copyright, database rights, design, and other intellectual\n" +
    "        property rights related to it, exclusively belong to Ihsan Katmer.\n" +
    "        <p/>\n" +
    "        We reserve the right to request that you remove all links or any\n" +
    "        particular link to our app or website. You approve to immediately\n" +
    "        remove all links to our app or website upon request. We also reserve\n" +
    "        the right to amend these terms and conditions and its linking policy\n" +
    "        at any time. By continuously linking to our app or website, you agree\n" +
    "        to be bound to and follow these linking terms and conditions.\n" +
    "        <p/>\n" +
    "        We are dedicated to ensuring that the app is as useful and efficient\n" +
    "        as possible. We reserve the right to alter the app or to charge for\n" +
    "        its services, at any time and for any reason. We will never charge you\n" +
    "        for the app or its services without making it very clear to you\n" +
    "        exactly what you’re paying for. In case you allow us to do so, we may\n" +
    "        send you messages and other content that we think may be of interest\n" +
    "        to you via e-mail or other methods (including mobile notifications).\n" +
    "        We may transfer its rights and obligations under this Terms and\n" +
    "        Conditions and the Privacy Policy at any time.\n" +
    "        <p/>\n" +
    "        The app is provided on an available basis. We can not guarantee the\n" +
    "        accuracy, reliability, or availability of the app. We shall not be\n" +
    "        liable for any direct, indirect, incidental, consequential, or\n" +
    "        exemplary damages arising from the use or inability to use the app.\n" +
    "        <p/>\n" +
    "        We recommend that you do not jailbreak or root your phone, which is\n" +
    "        the process of removing software restrictions and limitations imposed\n" +
    "        by the official operating system of your device. It could make your\n" +
    "        phone vulnerable to malware/viruses/malicious programs, compromise\n" +
    "        your phone’s security features and it could mean that the\n" +
    "        MuseumsAround app won’t work properly or at all.\n" +
    "        <p/>\n" +
    "        At some point, we may wish to update the app. The app is currently\n" +
    "        available on iOS – the requirements for the system(and for any\n" +
    "        additional systems we decide to extend the availability of the app to)\n" +
    "        may change, and you’ll need to download the updates if you want to\n" +
    "        keep using the app. We can not promise that it will always update the\n" +
    "        app so that it is relevant to you and/or works with the iOS version\n" +
    "        that you have installed on your device. However, you promise to always\n" +
    "        accept updates to the application when offered to you, We may also\n" +
    "        wish to stop providing the app and may terminate use of it at any time\n" +
    "        without giving notice of termination to you. Unless we tell you\n" +
    "        otherwise, upon any termination, (a) the rights and licenses granted\n" +
    "        to you in these terms will end; (b) you must stop using the app, and\n" +
    "        (if needed) delete it from your device.\n" +
    "        <p/>\n" +
    "        Even though we make all reasonable efforts to ensure that the content\n" +
    "        is accurate, we do not ensure that the information on the app is %100\n" +
    "        correct, we do not warrant its completeness or accuracy; nor do we\n" +
    "        promise to ensure that the app remains available or that the material\n" +
    "        on the app is kept up to date.\n" +
    "        <p/>\n" +
    "        Because it is not feasible to continuously update the app, the places\n" +
    "        mentioned within the App might have changed. Wandering in public space\n" +
    "        always includes some risk and we recommend you to watch your\n" +
    "        surroundings during the tour. Traveling on foot or by car while giving\n" +
    "        your attention to a mobile device can be extremely dangerous,\n" +
    "        therefore you should only use the App when you are standing on safe\n" +
    "        and stable ground. We take no responsibility for negative situations\n" +
    "        that may occur while you are checking the museum locations such as\n" +
    "        theft, traffic accidents, or physical harm.\n" +
    "        <p/>\n" +
    "        We are not responsible for any indirect damages that happen in\n" +
    "        relation to the App and any damage caused by negligence.\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Respect for other people’s legal rights</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            We respect the intellectual property rights of others and we expect\n" +
    "            our users to do the same. You may not use our app in a way that\n" +
    "            violates or infringes someone else’s rights of publicity, privacy,\n" +
    "            copyright, trademark, or other intellectual property rights.\n" +
    "        <p/>\n" +
    "        If this is the case and you’re a copyright owner or authorized to act\n" +
    "        on behalf of one, you can report to us the alleged copyright\n" +
    "        infringements.\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Removal of links from the application</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            If you find any link on the app that is offensive for any reason, you\n" +
    "            are free to contact and inform us at any moment. We will consider\n" +
    "            requests to remove links but we are not obligated to or so or to\n" +
    "            respond to you directly.\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Privacy</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            We respect your privacy and handle personal data in accordance with\n" +
    "            applicable laws and regulations as described in the <Link\n" +
    "                to=\"/privacy-policy\"\n" +
    "                className=\"text-xs text-decoration-underline\"\n" +
    "                reloadDocument={true}\n" +
    "        >\n" +
    "            Privacy Policy\n" +
    "            </Link>\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Resolution of Disputes, Applicable Law, and\n" +
    "                Severability</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            In the event of any dispute, claim, or controversy arising out of or\n" +
    "            relating to these terms and conditions, including any breach,\n" +
    "            termination, or validity thereof, the parties involved shall first\n" +
    "            attempt to resolve the Dispute through conciliation. The Conciliator\n" +
    "            shall conduct the conciliation proceedings in accordance with\n" +
    "            applicable rules and procedures. The parties agree to cooperate fully\n" +
    "            and in good faith with the Conciliator throughout the process.\n" +
    "        <p/>\n" +
    "        If the parties fail to reach a resolution through conciliation, either\n" +
    "        party may pursue other legal remedies available to them, as provided\n" +
    "        for in these terms and conditions or under applicable laws.\n" +
    "        <p/>\n" +
    "        This section on the resolution of disputes through conciliation shall\n" +
    "        survive any termination or expiration of these terms and conditions.\n" +
    "        <p/>\n" +
    "        Any disputes arising in connection with the Terms of Use, Privacy\n" +
    "        Policy, and its annexes as well as their interpretation shall be\n" +
    "        subject to Turkish Law, to the greatest extent permitted by Law and\n" +
    "        the Turkish versions of all these documents shall prevail against\n" +
    "        translations in other languages.\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Links to third party sites</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            The app may contain hyperlinks to the websites. If you access another\n" +
    "            website you do so at your own risk. We do not control such websites\n" +
    "            and are not responsible for their contents or the privacy or other\n" +
    "            practices of such websites. Further, it is up to you to take\n" +
    "            precautions to ensure that whatever links you select or software you\n" +
    "            download is free of such items as viruses, worms, Trojan Horses,\n" +
    "            defects, and other items of a harmful nature. The inclusion of\n" +
    "            hyperlinks to such websites does not imply any endorsement by us or\n" +
    "            any association with their operators. You agree that we are not\n" +
    "            responsible or liable, directly or indirectly, for any damage or loss\n" +
    "            caused by or in connection with the use of or reliance on any such\n" +
    "            content, goods, or services available on or through any such website.\n" +
    "        </p>\n" +
    "        <h6>\n" +
    "            <strong>Amendments to these Terms and Conditions and Additional\n" +
    "                Rules</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            We reserve the right to revise, remove or modify these Terms and\n" +
    "            Conditions at any time and for any reason. We will notify you of any\n" +
    "            changes by posting the new Terms and Conditions on this page. All such\n" +
    "            revisions, removals, and modifications shall be binding upon all\n" +
    "            Users, from the date on which they are made, in relation to the use of\n" +
    "            the Services by those Users. Your continued use of the Service shall\n" +
    "            constitute ongoing acceptance of these Terms and Conditions, as\n" +
    "            updated from time to time. Users should review these Terms and\n" +
    "            Conditions regularly, in order to familiarise themselves with any\n" +
    "            revisions that may have been made.\n" +
    "        </p>\n" +
    "        <span> This document is effective as of July 14, 2023</span>\n" +
    "        <h6>\n" +
    "            <strong>Contact</strong>\n" +
    "        </h6>\n" +
    "        <p>\n" +
    "            If you wish to make a complaint about our app or to raise any\n" +
    "            questions, comments, or concerns about the app, do not hesitate to\n" +
    "            contact us at <span><strong>museumsaround@gmail.com</strong></span>\n" +
    "        <p/>\n" +
    "        Any rights not expressly granted herein are reserved.\n" +
    "        </p>\n" +
    "    </div>\n" +
    "</div>\n" +
    "</body>\n" +
    "</html>"

import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { MdExplore, MdMap, MdMuseum, MdSearch } from "react-icons/md";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Start discovering!",
    paragraph:
      "Dive into the museums around you and gain a deeper understanding of their heritage by engaging with the past, present, and future.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header mb-4">
                  <MdExplore size={72} color="white" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">
                    🎨 Explore Museums from Anywhere
                  </h4>
                  <p className="m-0 text-sm">
                    Navigate through an enhanced map pinpointing both well-known
                    and lesser-known museums offering a surprising experience.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header mb-4">
                  <MdMap size={72} color="white" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">💾 Personalize Your Journey</h4>
                  <p className="m-0 text-sm">
                    Create your collection of favorite museums with a simple
                    tap. Whether it's an awe-inspiring museum or a hidden
                    underground gem, easily save them for future visits.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header mb-4">
                  <MdMuseum size={72} color="white" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">📚 Dive into Museum Details</h4>
                  <p className="m-0 text-sm">
                    Dive into museum profiles to acquire more detail,
                    information, and intriguing anecdotes in each location.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header mb-4">
                  <MdSearch size={72} color="white" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">🔎 Filter and Search with Ease</h4>
                  <p className="m-0 text-sm">
                    Looking for a specific type of museum or are you curious
                    about which museums open on Monday? With powerful filtering
                    options, It lets you to tailor your museum discovering
                    experience, ensuring you find the perfect match for your
                    interests.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="tiles-item reveal-from-bottom"
                 data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                        src={require(
                            './../../assets/images/feature-tile-icon-03.svg')}
                        alt="Features tile icon 03"
                        width={64}
                        height={64}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">
                    📚 Adventure Meets Education:
                  </h4>
                  <p className="m-0 text-sm">
                    Step into an immersive learning environment and gain deeper
                    insights into the cultural heritage, unravel hidden
                    narratives, and broaden your intellectual horizons that will
                    leave you in awe. Make each visit an unforgettable
                    experience. </p>
                </div>
              </div>
            </div>*/}

          {/* <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-04.svg')}
                                            alt="Features tile icon 04"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}

          {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-05.svg')}
                                            alt="Features tile icon 05"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}

          {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-06.svg')}
                                            alt="Features tile icon 06"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;

import React from "react";
import Hero from "../components/sections/Hero";
import { Footer } from "../components/sections/Footer";

import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const Home = () => {
  return (
    <>
      <Hero />
      {/* <FeaturesTiles /> */}
      {/*  <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split />*/}
      <Footer />
    </>
  );
};

export default Home;

import React from "react";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Image from "../elements/Image";

export const Footer = () => {
  return (
    <MDBFooter className="text-center text-lg-start text-muted">
      <div className="p-4 w-100 d-flex flex-md-row flex-column align-items-center justify-content-between gap-3 border-top">
        <div>
          <Image
            src={require("./../../assets/icons-ma/ic_museumsaround_white_outline.png")}
            width={100}
          />
        </div>
        <div>
          <div className="d-flex align-items-center gap-1">
            <Link
              to="/privacy-policy"
              className="text-xs text-decoration-underline"
              reloadDocument={true}
            >
              Privacy Policy
            </Link>
            <div style={{ width: 2 }} />
            <Link
              to="/terms-conditions"
              className="text-xs text-decoration-underline"
              reloadDocument={true}
            >
              Terms and Conditions
            </Link>
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: 13,
            }}
          >
            Copyright © 2024 MuseumsAround ®
          </div>
        </div>
        <div className="d-flex flex-column align-items-center gap-3">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <a href="https://instagram.com/museumsaroundapp?igshid=YmMyMTA2M2Y=">
              <MDBIcon fab icon="instagram" />
            </a>
            {/* <a href="/">
              <MDBIcon fab icon="facebook-f" />
            </a>
            <a href="/">
              <MDBIcon fab icon="twitter" />
            </a>
            <a href="/">
              <MDBIcon fab icon="linkedin" />
            </a> */}
          </div>
        </div>
      </div>
    </MDBFooter>
  );
};

import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { IconButton } from "@material-ui/core";
import iconUrl from "../../assets/icons-ma/ic_museumsaroundwhitelogo.png";
import Image from "../elements/Image";
import Button from "../elements/Button";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import AppleStoreButton from "../buttons/AppleStoreButton";
import PlayStoreButton from "../buttons/PlayStoreButton";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const options = ["Türkçe", "İngilizce"];

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className="hero-content">
            <img src={iconUrl} style={{ height: "100%", width: 300 }} />
            <h1
              style={{ fontSize: 30 }}
              className="mt-5 mb-25 reveal-from-bottom"
              data-reveal-delay="200"
            >
              ⚡️ Explore Cultural Heritage with{" "}
              <span className="text-color-primary">Museum 🏛 </span>
              App!
            </h1>
            <div className="container-sm hero-content-wrapper">
              <p
                className="m-0 mb-32 reveal-from-bottom align-self-center text-start"
                data-reveal-delay="400"
              >
                <span className="text-large">
                  Discover rich histories, artifacts, and exhibits right from
                  your device. Start your journey today!
                </span>
                <br />
                <br />
                <span className="text-small">
                  Begin your exploration of wonderful museums around the world
                  with MuseumsAround. From iconic cities to off-the-beaten-path
                  destinations, MuseumsAround will transport you across the
                  globe, unlocking a world of cultural wonders.
                </span>
              </p>

              <Image
                src={require("./../../assets/images/doublevertical.png")}
                className=""
              />
              {/*  <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://cruip.com/">
                    Get started
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button>
                </ButtonGroup>
              </div>*/}
            </div>
          </div>
          <div>
            <IconButton
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.lifeyapps.museumsaround"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <PlayStoreButton />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/museumsaround/id6447110231"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <AppleStoreButton />
            </IconButton>
          </div>
        </div>
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;

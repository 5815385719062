import React from "react";
import Home from "./views/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsAndConditions from "./views/TermsAndConditions";
import Blog from "./views/Blog";
import SupportScreen from "./views/Support";

const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: "museumsaroundcom.firebaseapp.com",
  projectId: "museumsaroundcom",
  storageBucket: "museumsaroundcom.appspot.com",
  messagingSenderId: "473687872125",
  appId: "1:473687872125:web:ad1b88e2f0ab6b7bcc9714",
  measurementId: "G-NJDPM1MTR0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

getAnalytics(app);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/support" element={<SupportScreen />} />
        {/* <Route path="users/*" element={<Users />} />*/}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
